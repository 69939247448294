import { useEffect, useState } from 'react';
import logo from './assets/images/logo.svg';
import './assets/styles/Home.css';

function Home() {
  const [baNo, setBaNo] = useState('沪ICP备06017533号-33');
  const [seq, setSeq] = useState('ba-33');

  useEffect(() => {
    if (window.location.hostname.match(/mcd\.cc$/)) {
      setBaNo('沪ICP备06017533号-35');
      setSeq('ba-35');
    }
  }, []);

  return (
    <div className="home">
      <header className="home-header">
        <img src={logo} className="logo" alt="logo" />
        {/* <h1>Mobi McDonalds.</h1> */}
        <div className="footer">
          <div>
            <a href="https://beian.miit.gov.cn/" className={seq}>{baNo}</a>
          </div>
          <div>©2022 McDONALD'S. All Rights Reserved</div>
        </div>
      </header>
    </div>
  );
}

export default Home;
